import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  TablePagination,
  InputAdornment,
} from "@mui/material";
// import { mdiHand } from "@mdi/js";
// import { TablePagination } from "@material-ui/core";
import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import {
  CustomCircularProgress,
  CustomDialogBox,
  NoData,
  ValidateButton,
} from "../../hoc/CustomComponents";
import KycAction from "./KycAction";
import classStyle from "./styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { debounce } from "throttle-debounce";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FileSaver from "file-saver";
import axios from "axios";
import { CustomButton } from "../../hoc/PaletteComponent";
import ImagePreview from "./PoiPoaPreview";

const APR_REJE_KYC_DETAILS = gql`
  mutation ($vin: String!, $kycType: String!, $kycRemarks: String) {
    approveRejectKyc(vin: $vin, kycType: $kycType, kycRemarks: $kycRemarks) {
      code
      message
    }
  }
`;
const GET_KYC_DETAILS = gql`
  query ($oemId: Int!, $limit: Int!, $offset: Int!, $kycSearch: String) {
    getKycDetails(
      oemId: $oemId
      limit: $limit
      offset: $offset
      kycSearch: $kycSearch
    ) {
      totalCount
      kycRows {
        imei
        serialNumber
        iccid
        vin
        engineNumber
        yearOfmg
        vehicleMake
        vehicleModel
        fuelType
        customerID
        customerName
        customerMobile1
        retailInvoiceNumber
        customerAddress
        customerEmail
        proofOfAddress
        proofOfIdentity
        dealerCode
        dealerName
        dealerAddress
        dealerTown
        dealerContactName
        dealerContactNumber
        kyccount
        kycApprovedDate
        kycRejectedDate
        lastKycReceivedDate
        vehicleActivationDate
        kycMode
        ticketCreated
        status
        rejectionReason
        simPlanType
        simSubscriptionStartDate
        simSubscriptionEndDate
      }
    }
  }
`;

const EXCEL_DOWNLOAD = gql`
  mutation ($oemId: Int!, $downloadFor: String!) {
    excelFileDownload(oemId: $oemId, downloadFor: $downloadFor) {
      downloadUrl
    }
  }
`;

const GET_DOWNLOAD_URL = gql`
  query ($bucketName: String!, $filename: String!) {
    getKycDownloadURL(bucketName: $bucketName, filename: $filename) {
      code
      remarks
      url
    }
  }
`;

const columns = [
  {
    id: "no",
    label: "Sr.No",
    minWidth: 100,
  },
  {
    id: "vin",
    label: "VIN",
    minWidth: 200,
  },
  {
    id: "imei",
    label: "IMEI",
    minWidth: 200,
  },
  {
    id: "engineNumber",
    label: "Engine Number",
    minWidth: 150,
  },
  {
    id: "yearOfmg",
    label: "Year",
    minWidth: 150,
  },
  {
    id: "vehicleMake",
    label: "Vehicle Make",
    minWidth: 150,
  },
  {
    id: "vehicleModel",
    label: "Vehicle Model",
    minWidth: 150,
  },
  {
    id: "fuelType",
    label: "Fuel Type",
    minWidth: 150,
  },
  {
    id: "customerID",
    label: "Customer ID",
    minWidth: 150,
  },

  {
    id: "customerName",
    label: "Customer Name",
    minWidth: 350,
  },
  {
    id: "customerMobile",
    label: "Customer Mobile",
    minWidth: 200,
  },
  {
    id: "retailInvoiceNumber",
    label: "Retail Invoice Number",
    minWidth: 200,
  },
  {
    id: "customerAddress",
    label: "Customer Address",
    minWidth: 500,
  },
  {
    id: "customerEmail",
    label: "Customer Email",
    minWidth: 150,
  },
  {
    id: "dealerCode",
    label: "Dealer Code",
    minWidth: 150,
  },
  {
    id: "dealerName",
    label: "Dealer Name",
    minWidth: 350,
  },
  {
    id: "dealerAddress",
    label: "Dealer Address",
    minWidth: 500,
  },
  {
    id: "dealerTown",
    label: "Dealer Town",
    minWidth: 150,
  },
  {
    id: "dealerContactName",
    label: "Dealer ContactName",
    minWidth: 200,
  },
  {
    id: "kyccount",
    label: "KYC Count",
    minWidth: 150,
  },

  {
    id: "kycApprovedDate",
    label: "KYC Approved Date",
    minWidth: 200,
  },
  {
    id: "kycRejectedDate",
    label: "KYC Rejected Date",
    minWidth: 200,
  },

  {
    id: "lastKycReceivedDate",
    label: "Last KYC Received Date",
    minWidth: 200,
  },
  // {
  //   id: "vehicleActivationDate",
  //   label: "Vehicle Activation Date",
  //   minWidth: 150,
  // },
  {
    id: "kycMode",
    label: "KYC Mode",
    minWidth: 150,
  },
  {
    id: "proofofaddress",
    label: "Proof of Address",
    minWidth: 200,
  },
  {
    id: "proofofidentity",
    label: "Proof of Identity",
    minWidth: 200,
  },
  {
    id: "ticketCreated",
    label: "Ticket Created",
    minWidth: 150,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 150,
  },
  {
    id: "rejectionReason",
    label: "Rejection Reason",
    minWidth: 800,
  },
  {
    id: "approve",
    label: "Approve",
    minWidth: 200,
  },
  {
    id: "reject",
    label: "Reject",
    minWidth: 200,
  },
];

const statusOptions = [
  "All",
  // "VIN-IMEI Mapped",
  // "VIN_EOLT_completed",
  "KYC_RECEIVED",
  // "IMEI_NOT_MAPPED",
  "KYC_APPROVED",
  "KYC_REJECTED",
];
function KycDetails(props) {
  const classes = classStyle();
  const [openRej, setopenRej] = useState(false);
  const [reason, setReason] = useState();
  const [openApprove, setApprove] = useState(false);
  const [searchInput, setSearchInput] = useState();
  const [kycDetails, setKycDetails] = useState([]);
  let [next, setNext] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [kycId, setKycId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [allData, setAlldata] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(null);
  const [previewUrl, setUrl] = useState(null);
  const [show, setShow] = useState(false);
  const [file, setuploadFile] = useState(null);
  const [POA, setPOA] = useState(true);
  const [POI, setPOI] = useState(true);
  const [previewType, setPreviewType] = useState("");
  const [fileType, setFileType] = useState(null);
  const [selectedFilter, setSelFilter] = useState("");

  /**
   * @summary This handles the page change in pagination
   */
  const handleChangePage = async (event, newPage) => {
    setSearchInput("");
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    await KycDetails({
      variables: { oemId: props.oem, limit: rowsPerPage, offset: newPage , kycSearch: selectedFilter},
    });
  };

  /**
   * @summary This handles the row change in pagination
   */
  const handleChangeRowsPerPage = async (event) => {
    setSearchInput("");
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    await KycDetails({
      variables: { oemId: props.oem, limit: event.target.value, offset: 0 , kycSearch: selectedFilter},
    });
  };

  /**
   * @summary opens the Rejection model to select reason
   */

  const handleReject = (vin) => {
    setKycId(vin);
    setopenRej(true);
  };

  /**
   * @summary opens the Approval model
   */
  const handleAprove = (vin) => {
    setApprove(true);
    setKycId(vin);
  };

  /**
   * @summary closes the Approval model
   */
  const cancelApprove = () => {
    setApprove(false);
    setKycId(null);
  };

  /**
   * @summary closes the Rejection model
   */
  const closeReject = () => {
    setopenRej(false);
    setKycId(null);
    setReason(null);
  };

  /**
   * @summary closes the snackbar
   */
  const handleClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  /**
   * @summary call the api to approve the kyc
   */
  const approveKyc = async () => {
    console.log(kycId, "kycId");
    setSearchInput("");

    await aprRejectKyc({
      variables: {
        vin: kycId,
        kycType: "KYC_APPROVED",
      },
    });
  };

  /**
   * @summary call the api to reject the kyc
   */
  const rejectKyc = async () => {
    console.log(reason, kycId);
    setSearchInput("");
    await aprRejectKyc({
      variables: {
        vin: kycId,
        kycType: "KYC_REJECTED",
        kycRemarks: reason,
      },
    });
  };

  /**
   * @summary api call for approval and rejection
   */
  const [aprRejectKyc, { error: Kycerror, loading: Kycloading, data: res }] =
    useMutation(APR_REJE_KYC_DETAILS, {
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: Internal Server Error`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
        cancelApprove();
        closeReject();
        handleClose();
      },
      onCompleted: (result) => {
        props.setMessage(result.approveRejectKyc.message);
        props.setNetworkError(true);
        props.setSnackbar(true);

        KycDetails({
          variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
        });
        cancelApprove();
        closeReject();
        handleClose();
      },
    });

/**
   * @summary handles Search value
   */
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

/**
 * @summary returns the filtered array for search
 */
const debounceSearchRender = useCallback(debounce(async (input) => {
  console.log(props)
  if (input) {
      fetchKycDetailsForSearch({
        variables: {
          oemId: props.oem,
          limit: totalCount,
          offset: 0,
          kycSearch: input.trim(),
        },
      });
    } else {
      KycDetails({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
    }
}, 1000), [props.oem,totalCount, rowsPerPage, page]);

const handleSearchChange = (event) => {
  const input = event.target.value;
  setSearchInput(input);
  debounceSearchRender(input);
};

  // /**
  //  * @summary handles Search value
  //  */
  // const handleSearchChange = (event) => {
  //   setSearchInput(event.target.value);
  //   setTimeout(debounceSearchRender(event.target.value), 1000);
  // };

  // /**
  //  * @summary returns the filtered array for search
  //  */
  // const debounceSearchRender = debounce(1000, async (event) => {
  //   if (event) {
  //     fetchKycDetailsForSearch({
  //       variables: {
  //         oemId: props.oem,
  //         limit: totalCount,
  //         offset: 0,
  //         kycSearch: event.trim(),
  //       },
  //     });
  //   } else {
  //     KycDetails({
  //       variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
  //     });
  //   }
  // });

  const [fetchKycDetailsForSearch] = useLazyQuery(GET_KYC_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: async (result) => {
      console.log("completed");
      setKycDetails(result.getKycDetails.kycRows);
      setTotalCount(result.getKycDetails.totalCount);
    },
  });

  /**
   * @summary opens the pop up to select status
   */
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @summary filters the data on status
   */
  const handleStatusChange = async (status) => {
    setSearchInput("");
    setNext(0);
    setPage(0);

    if (status == "All") {
      setSelFilter("");
      await KycDetails({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: 0 },
      });
    } else {
      setSelFilter(status);
      fetchKycDetailsForSearch({
        variables: {
          oemId: props.oem,
          // limit: totalCount,
          offset: 0,
          limit: rowsPerPage,
          kycSearch: status.trim(),
        },
      });

      // const mappedData =
      //   status && allData.filter((row) => row.status === status);
      // setKycDetails(mappedData);
    }
    setAnchorEl(null);
  };

  /**
   * @summary fetch kyc details for Status All
   */
  const [fetchKycDetailsForStatus, { data: kyc }] = useLazyQuery(
    GET_KYC_DETAILS,
    {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
        handleClose();
      },
      onCompleted: async (result) => {
        console.log("completed");
        setKycDetails(result.getKycDetails.kycRows);
        setTotalCount(result.getKycDetails.totalCount);
      },
    }
  );

  const [KycDetails, { error: error, loading: loading, data: Details }] =
    useLazyQuery(GET_KYC_DETAILS, {
      fetchPolicy: "network-only",

      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Internal Server Error`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
        handleClose();
      },
      onCompleted: async (result) => {
        setRowsPerPage(rowsPerPage);
        setKycDetails(result.getKycDetails.kycRows);
        setTotalCount(result.getKycDetails.totalCount);
      },
    });

  useEffect(() => {
    KycDetails({
      variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
    });
    //removed previous snackbar state
    props.setSnackbar(false);
  }, [props.oem]);

  const [getDownloadUrl, { data: url }] = useMutation(EXCEL_DOWNLOAD, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: async (result) => {
      const res = await axios({
        url: "",
        method: "GET",
        headers: { Accept: "application/vnd.ms-excel" },
        responseType: "blob", // important
      });

      FileSaver.saveAs(new Blob([res.data]), "sample.xlsx");
    },
  });

  /**
   * @summary previews the poi & poa file
   */
  const [getPreviewUrl, { data: previeUrl }] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: async (result) => {
      console.log(result);

      setUrl(result.getKycDownloadURL.url);
      setPreviewOpen(true);
    },
  });

  const DownloadPreview = (filename, e) => {
    setPreviewType(e);
    console.log(filename.split(".")[1], "ppp");
    setFileType(filename.split(".")[1]);
    getPreviewUrl({
      variables: {
        // bucketName: "customerkyc-qa",
        bucketName:  process.env.REACT_APP_AZURE_CUSTOMERKYC_CONTAINER,
        filename: filename,
      },
    });
  };

  const handlePreviewClose = () => {
    setUrl(null);
    setPreviewOpen(false);
    setFileType(null);
  };

  const DownloadExcel = () => {
    getDownloadUrl({
      variables: {
        oemId: props.oem,
        downloadFor: "DEVICE_MANAGER",
      },
    });
  };

  return (
    <React.Fragment>
      <KycAction
        open={openRej}
        cancelRejection={closeReject}
        setReason={setReason}
        reason={reason}
        rejectKyc={rejectKyc}
      />
      <CustomDialogBox
        open={openApprove}
        content={`Are you sure you want to approve this KYC Details?`}
        negativeResponseHandler={cancelApprove}
        negativeResponseButtonText="Cancel"
        positiveResponseHandle={approveKyc}
        positiveResponseButtonText="Approve"
      />
      {previewOpen ? (
        <ImagePreview
          previewOpen={previewOpen}
          handlePreviewClose={handlePreviewClose}
          url={previewUrl}
          previewType={previewType}
          fileType={fileType}
        />
      ) : null}

      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item>
              <Typography variant="h4">KYC Details</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingTop:'0px' }}
            >
              <Grid
                container
                style={{
                  backgroundColor: "#FFFFFF",
                  paddingTop: "0px",
                  // marginLeft: "-10px !important",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingBottom: "8px",
                    paddingTop: "8px",
                    paddingLeft: "10px",
                  }}
                >
                  <TextField
                    placeholder="Search Number"
                    variant="outlined"
                    value={searchInput}
                    onChange={(e) => handleSearchChange(e)}
                    style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ paddingLeft: "3px" }} />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.textfield}
                  />
                  <IconButton
                    onClick={handleFilterClick}
                    // disabled={totalCount == 0}
                  >
                    <FilterListIcon style={{ fill: "#57B793" }} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    {statusOptions.map((status) => (
                      <MenuItem
                        key={status}
                        onClick={() => handleStatusChange(status)}
                      >
                        {status}
                      </MenuItem>
                    ))}
                  </Menu>
                  {/* <IconButton
                    title="Download Excel"
                    aria-label="Download Excel"
                    disabled={totalCount == 0}
                    onClick={DownloadExcel}
                  >
                    <CloudDownloadIcon
                      style={{ fill: "#57B793", size: "large" }}
                    />
                  </IconButton> */}
                </Grid>

                <Grid item xs={12} style={{maxHeight:'60vh'}}>
                  {loading ? (
                    <CustomCircularProgress />
                  ) : kycDetails && kycDetails.length > 0 ? (
                    <Paper>
                      <TableContainer
                        style={{ maxHeight: "calc(100vh - 260px)" }}
                        className={classes.container}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  align="center"
                                  style={{
                                    minWidth: column.minWidth,
                                    font: "normal normal 600 15px/20px Nunito Sans",
                                    backgroundColor: "#faf8f7",
                                  }}
                                  className={classes.tableHead}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {kycDetails.map((row, index) => {
                              let kycApprovedDate = row.kycApprovedDate
                                ? moment
                                    .unix(row.kycApprovedDate)
                                    .format("DD-MM-YYYY HH:mm:ss")
                                : null;
                              let kycRejectedDate = row.kycRejectedDate
                                ? moment
                                    .unix(row.kycRejectedDate)
                                    .format("DD-MM-YYYY HH:mm:ss")
                                : null;
                              let lastKycReceivedDate = row.lastKycReceivedDate
                                ? moment
                                    .unix(row.lastKycReceivedDate)
                                    .format("DD-MM-YYYY HH:mm:ss")
                                : null;
                              let vehicleActivationDate =
                                row.vehicleActivationDate
                                  ? moment
                                      .unix(row.vehicleActivationDate * 1000)
                                      .format("DD-MM-YYYY HH:mm:ss")
                                  : null;
                              return (
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {next + (index + 1)}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.vin}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.imei ? row.imei : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.engineNumber}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.yearOfmg ? row.yearOfmg : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.vehicleMake ? row.vehicleMake : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.vehicleModel}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.fuelType}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.customerID ? row.customerID : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.customerName ? row.customerName : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.customerMobile1}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.retailInvoiceNumber
                                      ? row.retailInvoiceNumber
                                      : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.customerAddress
                                      ? row.customerAddress
                                      : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.customerEmail
                                      ? row.customerEmail
                                      : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.dealerCode ? row.dealerCode : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.dealerName}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.dealerAddress}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.dealerTown}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.dealerContactName}
                                  </TableCell>
                                  {/* <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.proofOfAddress}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.proofOfIdentity}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.dealerContactNumber}
                                </TableCell> */}
                                  {/* <TableCell
                                  align="center"
                                  className={classes.tableContent}
                                >
                                  {row.dealerContactNumber}
                                </TableCell> */}
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.kyccount}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {kycApprovedDate ? kycApprovedDate : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {kycRejectedDate ? kycRejectedDate : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {lastKycReceivedDate
                                      ? lastKycReceivedDate
                                      : "NA"}
                                  </TableCell>
                                  {/* <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {vehicleActivationDate}
                                  </TableCell> */}
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.kycMode}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.proofOfAddress &&
                                    row.proofOfAddress != "N/A" ? (
                                      <CustomButton
                                        variant="contained"
                                        onClick={() =>
                                          DownloadPreview(
                                            row.proofOfAddress,
                                            "Proof of Address"
                                          )
                                        }
                                        // style={{
                                        //   padding: "0px",
                                        //   fontSize: "12px",
                                        // }}
                                      >
                                        Preview
                                      </CustomButton>
                                    ) : (
                                      " NA"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.proofOfIdentity &&
                                    row.proofOfIdentity != "N/A" ? (
                                      <CustomButton
                                        variant="contained"
                                        onClick={() =>
                                          DownloadPreview(
                                            row.proofOfIdentity,
                                            "Proof Of Identity"
                                          )
                                        }
                                      >
                                        Preview
                                      </CustomButton>
                                    ) : (
                                      " NA"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.ticketCreated}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.status}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.rejectionReason
                                      ? row.rejectionReason
                                      : "NA"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    <CustomButton
                                      variant="contained"
                                      onClick={() => handleAprove(row.vin)}
                                      style={
                                        row.status != "KYC_RECEIVED"
                                          ? {
                                              backgroundColor: "#dad6d7",
                                              color: "#141414",
                                            }
                                          : {
                                              background:
                                                "#E0FFF3 0% 0% no-repeat padding-box",
                                              color: "#57B793",
                                            }
                                      }
                                      disabled={row.status != "KYC_RECEIVED"}
                                    >
                                      Approve
                                    </CustomButton>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    <button
                                      style={
                                        row.status != "KYC_RECEIVED"
                                          ? {
                                              border: "1px solid #777777",
                                              borderRadius: "28px",
                                              backgroundColor: "#dad6d7",
                                              color: "#808080",
                                              paddingLeft: "11px",
                                              paddingRight: "11px",
                                            }
                                          : {
                                              border: "1px solid #777777",
                                              borderRadius: "28px",
                                              backgroundColor: "#F70D1A",
                                              paddingLeft: "11px",
                                              paddingRight: "11px",
                                            }
                                      }
                                      onClick={() => handleReject(row.vin)}
                                      disabled={row.status != "KYC_RECEIVED"}
                                    >
                                      REJECT
                                    </button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[20,50, 100]}
                        component="div"
                        count={kycDetails && totalCount > 0 ? totalCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  ) : (
                    <NoData />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default withSnackbar(KycDetails);
